import * as React from "react"
import { withStyles, Container, Box, Typography, Button } from '@material-ui/core/';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import './hero.css'
const banners = [
  {
    id: 0,
    image: 'https://images.unsplash.com/photo-1483118714900-540cf339fd46?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80',
    title: 'Summer 2020',
    subTitle: 'New Arrival Collection'
  }, {
    id: 1,
    image: 'https://images.unsplash.com/photo-1602810318383-e386cc2a3ccf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
    title: 'Summer 2020',
    subTitle: 'New Arrival Collection'
  }, {
    id: 2,
    image: 'https://images.unsplash.com/photo-1602810316498-ab67cf68c8e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
    title: 'Summer 2020',
    subTitle: 'New Arrival Collection'
  }
]
const staticImage = 'https://images.unsplash.com/photo-1483118714900-540cf339fd46?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80'
const Banner = ({ classes, siteTitle, bannerData }) => (
  <div>
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      totalSlides={bannerData?.length}
      touchEnabled
      dragEnabled
      isPlaying
      className={`rsm--carousel--hero ${classes.root}`}
    >
      <Slider>
        {bannerData?.map((item, i) => (
          <Slide index={i} key={item.Id}>
            <div className='bannerBg'
              style={{
                backgroundImage: `url(${staticImage})`,
                height: '100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative'
              }}>
              <Container>
                <Box paddingX={10} className='bannerContent'>
                  <div>
                    <Typography variant='subtitle1' className={classes.subtitle}>
                      {item.Name}
                    </Typography>
                    <Typography variant='h2' component='h1' gutterBottom className={classes.title}>
                      {item.Description}
                    </Typography>
                    <a href=''>
                      <Button variant='contained' color='primary' size='large'>
                        Explore Now
                      </Button>
                    </a>
                  </div>
                </Box>
              </Container>
            </div>
          </Slide>
        ))}
      </Slider>
      <div className='rsm--carousel__actions'>
        <ButtonBack className={classes.navigationButton}>
          <ChevronLeft className={classes.icon} />
        </ButtonBack>
        <ButtonNext className={classes.navigationButton}>
          <ChevronRight className={classes.icon} />
        </ButtonNext>
      </div>
    </CarouselProvider>
  </div>
)

const styles = (theme) => ({
  root: {
    '& .carousel__slider': {
      height: '100vh'
    },
    '& .carousel__inner-slide': {
      height: '100vh'
    },
    [theme.breakpoints.down('md')]: {
      '& img': {
        width: 'auto'
      },
      '& .carousel__slider': {
        height: '50vh'
      },
      '& .carousel__inner-slide': {
        height: '50vh'
      }
    }
  },
  title: {
    fontWeight: 'bold'
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    letterSpacing: '1px',
    // color: '#222222'
  },
  navigationButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#fff'
  },
  icon: {
    fontSize: '3rem'
  },
  carouselContainer: {
    '& .BrainhubCarousel__arrows': `
      position: absolute;
      z-index: 3;
      background-color: #f26b3e;    
    `,
    '& .BrainhubCarousel__arrowRight': `
      right: 0px;
    `,
    '& .BrainhubCarousel__arrowLeft': `
      left: 0px;
    `
  }
})

export default withStyles(styles, { withTheme: true })(Banner)
