import * as React from "react"
import { Link, navigate } from "gatsby"
import { withStyles, Button, CircularProgress } from '@material-ui/core/';
import { default as MaterialUiImage } from 'material-ui-image';

const CategoryItem = ({ classes, title, slug, image, subCategoryId, categoryList, menuList, menuId, productExists }) => {
  console.log("productExists", productExists)

  const handleClickCategory = () => {
    if (productExists === 1) {
      navigate(`/products/${slug}/`);
    } else {
      navigate(`/categories/${slug}?menu=${menuId}`);
    }
    // if (mobileView) {
    //   setOpenDrawer(false);
    // }
  }

  return (
    <div className='categoryItem'>
      {/* <Link to={categoryList ? `/products/${subCategoryId}/${slug}/${menuId}` : menuList ? `/categories/${slug}?menu=${menuId}` : `categories/${slug}`}> */}
      {/* <Link to={categoryList ? `/products/${slug}/${subCategoryId}` : menuList ? `/categories/${slug}?menu=${menuId}` : `categories/${slug}`}> */}
      <a onClick={() => handleClickCategory()} style={{ cursor: 'pointer' }}>
        {/* <img src={image} alt={title} /> */}
        <MaterialUiImage loading={<CircularProgress color="secondary" />} src={image} alt={title} disableTransition cover />
        <span>
          <Button variant='contained' color='white'>
            {title}
          </Button>
        </span>
      </a>
    </div>
  )
}

const styles = (theme) => ({
  root: {

  },
})

export default withStyles(styles, { withTheme: true })(CategoryItem)
