import * as React from "react";
import { Link } from "gatsby";
import { withStyles, Typography, Button, IconButton, CircularProgress, Fade } from '@material-ui/core/';
import { FiShoppingCart } from 'react-icons/fi';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { default as MaterialUiImage } from 'material-ui-image';
import { BiRupee } from 'react-icons/bi';

const ProductItem = ({ classes, title, slug, image, price, productId, ids }) => {
  console.log('ids', ids)
  return (
    <Fade in={title} {...(title ? { timeout: 1000 } : {})}>
      <Button className={classes.root}>
        <Link to={`/product/${productId}?${slug}`} style={{ height: '400px', overflow: 'hidden', marginBottom: '16px', borderRadius: '5px', border: '1px solid #eee' }}>
          <MaterialUiImage loading={<CircularProgress color="secondary" />} src={image} alt='product' disableTransition style={{ height: '400px', objectFit: 'cover' }} objectFit={false} />
        </Link>
        <Link to={`/product/${productId}?${slug}`}><Typography variant='h6'>{title}</Typography>
          <Typography variant='body2' style={{ color: '#696969' }}><BiRupee /> {price ? price : '000'}</Typography>
        </Link>
        <span className='wishButton'>
          <IconButton aria-label="wishlist">
            <FavoriteBorderIcon style={{ color: '#fff' }} />
          </IconButton>
        </span>
        <span className='cartButton'>
          <Link to={`/product/${productId}?${slug}`}>
            <Button variant='contained' color='secondary' className='borderRadius'>
              Add to cart <FiShoppingCart style={{ margin: '0 8px' }} />
            </Button>
          </Link>
        </span>
      </Button>
    </Fade>
  )
}


const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
    textAlign: 'left',
    padding: '0 !important',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(3),
    width: '100%',
    borderRadius: '0 !important',
    '& a': {
      display: 'block',

    },
    '& h6': {
      fontSize: '18px',
      letterSpacing: '1px',
      fontWeight: '400',
      marginBottom: '7px'
    },
    '& img': {
      transition: '0.8s',
      height: '400px',
      width: '100%',
      objectFit: 'cover',
    },
    '& .cartButton': {
      position: 'absolute',
      dkisplay: 'none',
      transform: 'translate(0px, 100%)',
      transition: 'all 0.8s ease',
      opacity: 0,
      width: '100%',
      textAlign: 'center'
    },
    '& .wishButton': {
      position: 'absolute',
      top: 0,
      transition: '0.8s',
      display: 'none',
      opacity: 0,
    },
    '&:hover': {
      background: 'transparent',
      '& img': {
        transform: 'scale(1.2)'
      },
      '& .cartButton': {
        display: 'block',
        bottom: '200px',
        transform: 'translate(0px, 0px)',
        transition: 'all 0.8s ease',
        opacity: 1
      },
      '& .wishButton': {
        display: 'block',
        opacity: 1,
      }
    }
  },
})

export default withStyles(styles, { withTheme: true })(ProductItem)
