import * as React from "react";
import { withStyles, Box} from '@material-ui/core/';
import Skeleton from '@material-ui/lab/Skeleton';

const ProductItemLoader = ({ classes }) => {
  
  return (
    <>
      <Skeleton variant="rect" width='100%' height={400} color='secondary' size={100} />
      <Box height='16px' />
      <Skeleton />
      <Box height='16px' />
      <Skeleton width="60%" />
    </>
  )
}


const styles = (theme) => ({

})

export default withStyles(styles, { withTheme: true })(ProductItemLoader)
