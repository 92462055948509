import * as React from "react"
import { withStyles } from '@material-ui/core/';
import { Typography } from '@material-ui/core/';

const PageTtitle = ({ classes, title, subTitle }) => (
  <div className={classes.pageTitle}>
    <Typography variant='h2'><span>{title}</span></Typography>
    <em>{subTitle}</em>
  </div>
)
const styles = (theme) => ({
  pageTitle: {
    textAlign: 'center',
    paddingBottom: '24px',
    '& h2': {
      position: 'relative',
      zIndex: 1,
      fontWeight: 'bold',
      color: '#222',
      fontSize: '38px',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      '& span': {
        background: '#fff',
        padding: '0 35px'
      },
    },
    '& em': {
      margin: '8px 0',
      display: 'block',
      color: '#999'
    },
    '& h2::before': {
      borderTop: '2px solid #222',
      content: `""`,
      margin: '0 auto',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      bottom: 0,
      width: '35%',
      zIndex: '-1'
    },
  }
})

export default withStyles(styles, { withTheme: true })(PageTtitle)
