import * as React from "react"
import { withStyles, Container, Box } from '@material-ui/core/';
import CategoryItem from './CategoryItem';
import { useAllProductsData } from '../helpers';
import Skeleton from '@material-ui/lab/Skeleton';

const image = 'https://images.unsplash.com/photo-1622497170185-5d668f816a56?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1921&q=80'
const Categories = ({ classes }) => {
  const allProducts = useAllProductsData();
  const categories = allProducts?.mainSubCategoryData.slice(0, 4);
  const categoriesLoading = allProducts?.mainSubCategoryDataLoading;

  return (
    <Box className={classes.root} paddingY={6}>
      <Container>
        <div className={classes.gridContainer}>
          {categoriesLoading ? <>
            {Array.apply(null, { length: 4 }).map((e, i) => (<>
              <Box>
                <Skeleton variant="rect" width='100%' color='secondary' size={100} height='100%' />
              </Box>
            </>))}
          </> : <>
            {categories?.map((item, i) => (
              <CategoryItem productExists={item.Productexists} key={i} title={item.MenuName} slug={item.MenuId} image={item.Images && item.Images[0]?.MediaURL} loading={categoriesLoading} />
            ))}
          </>}
        </div>
      </Container>
    </Box>
  )
}

const styles = (theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridColumnGap: '25px',
    gridRowGap: '25px',
    height: '75vh',
    '& div:first-child': {
      gridArea: '1 / 1 / 3 / 3',
      // height: '750px'
    },
    '& div:nth-child(2)': {
      gridArea: '1 / 3 / 2 / 4',
    },
    '& div:nth-child(3)': {
      gridArea: '2 / 3 / 3 / 4',
    },
    '& div:nth-child(4)': {
      gridArea: '1 / 4 / 3 / 5',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      height: 'auto',
      '& .categoryItem': {
        marginBottom: '16px'
      }
    }
  },
})

export default withStyles(styles, { withTheme: true })(Categories)
