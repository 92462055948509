import * as React from "react"
import { withStyles, Grid, Box, Container, Typography, Button, CircularProgress, Grow, Paper, List, ListItemText, ListItem, ClickAwayListener } from '@material-ui/core/';
import Skeleton from '@material-ui/lab/Skeleton';
import PageTtitle from './PageTtitle';
import ProductItem from './ProductItem';
import ProductItemLoader from './ProductItemLoader';
import { RiEqualizerLine } from 'react-icons/ri';
import { BsChevronDown } from 'react-icons/bs';
import { BsChevronUp } from 'react-icons/bs';
import './productList.css';
import { Link } from "gatsby";

const ProductList = ({ classes, title, subTitle, data, listPage, bgImage, loading, ids, breadcrumbLink }) => {
  const [openFeatured, setOpenFeatured] = React.useState(false);
  const [filterParam, setFilterParam] = React.useState('Featured');
  console.log('data', data);
  console.log('breadcrumbLink', breadcrumbLink);

  return (
    <Box className={classes.root} paddingY={!listPage && 6}>
      {listPage ? <>
        <Box className='listPageTitleContainer' style={{ backgroundImage: `url(${bgImage})` }}>
          <h2>{title}</h2>
          {/* <em>{subTitle}</em> */}
          <em> {breadcrumbLink && <> <Link to={`/categories/${breadcrumbLink.MenuId}`} style={{ color: '#fff' }}><em>{breadcrumbLink.MenuName}</em></Link><em style={{ margin: '0 8px' }}>/</em>
            <Link to={`/categories/${breadcrumbLink.CategoryId}?menu=${breadcrumbLink.MenuId}`} style={{ color: '#fff' }}><em>{breadcrumbLink.Category}</em></Link>{breadcrumbLink.SubCategory && <><em style={{ margin: '0 8px' }}>/</em> {breadcrumbLink.SubCategory}</>}</>}
          </em>
        </Box>
      </> : <PageTtitle title={title} subTitle={subTitle} />}

      <Box paddingTop={listPage && 5}>
        <Container>
          {data?.length === 0 ? <Box display='flex' alignItems='center' justifyContent='center' style={{ height: '50vh', width: '100%', flexDirection: 'column' }}>
            <Typography variant='h5' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Oops! No data found.
            </Typography>
          </Box> : <>
            {listPage &&
              <Box paddingBottom={4} display='flex' alignItems='center' justifyContent='space-between'>
                <Typography style={{ display: 'flex', alignItems: 'center' }}><RiEqualizerLine /> <Box width='8px' />Filter</Typography>
                <Box className='rightFilterDropdown'>
                  <ClickAwayListener onClickAway={() => setOpenFeatured(false)}>
                    <Box className='rightFilterButton' onClick={() => setOpenFeatured(!openFeatured)}>
                      <Typography >{filterParam}</Typography>
                      {openFeatured ? <BsChevronUp /> : <BsChevronDown />}
                    </Box>
                  </ClickAwayListener>
                  <Grow
                    className={`rightFilterDropdownMenu ${openFeatured && 'opened'}`}
                    in={openFeatured}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(openFeatured ? { timeout: 500 } : {})}
                  >
                    <Paper elevation={4} className={classes.paper}>
                      <Box>
                        <List component="nav" aria-label="main mailbox folders">
                          <ListItem button onClick={() => setFilterParam('Featured')}>
                            <ListItemText primary="Featured" />
                          </ListItem>
                          <ListItem button onClick={() => setFilterParam('Price, Low to high')}>
                            <ListItemText primary="Price, Low to high" />
                          </ListItem>
                          <ListItem button onClick={() => setFilterParam('Price, High to low')}>
                            <ListItemText primary="Price, High to low" />
                          </ListItem>
                          <ListItem button onClick={() => setFilterParam('Best Selling')}>
                            <ListItemText primary="Best Selling" />
                          </ListItem>
                        </List>
                      </Box>
                    </Paper>
                  </Grow>
                </Box>
              </Box>}

            {loading ?
              <Grid container spacing={3}>
                {Array.apply(null, { length: 4 }).map((e, i) => (
                  <Grid item md={3} lg={3} xs={12} sm={12} key={i}>
                    <ProductItemLoader />
                  </Grid>
                ))}
              </Grid>
              : <Grid container spacing={3}>
                {data?.map((item, i) => (
                  <Grid item md={3} lg={3} xs={12} sm={12} key={i}>
                    <ProductItem image={item.Images && item.Images[0]?.MediaURL} title={item.ProductName} slug={title} price={item.Price} productId={item.ProductId} ids={ids} />
                  </Grid>
                ))}
              </Grid>}
            {data?.length > 10 ? <>
              <Box display='flex' justifyContent='center' alignItems='center' paddingY={5}>
                <Button variant='outlined' color='secondary' className='borderRadius'>Load More</Button>
              </Box></> : ''}
          </>}

        </Container>
      </Box>
    </Box>
  )
}


const styles = (theme) => ({
  root: {

  },
})

export default withStyles(styles, { withTheme: true })(ProductList)
